// pages/patient.js
import React from "react"
import { graphql　} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import List from "../components/list"
import Top from "../components/top"
import News from "../components/news"

const IndexPage = ({ data }) => (
  <Layout>
  <SEO title="藤井 春日" image={data.allMicrocmsTop.edges[0].node.main_photo[0].image.url} />
  <div className="p_index contents">
    <Top data={data.allMicrocmsTop.edges[0].node.main_photo}/>
    <div className="p_index_news">
      <div className="p_index_news_outer inner_pc_tb">
        <h2 className="p_index_news_ttl">Information</h2>
        <News data={data.allMicrocmsInformation.edges} />
      </div>
    </div>
    <List data={data.allMicrocmsPhoto} />
  </div>
  </Layout>
)

export const query = graphql`
 {
  allMicrocmsPhoto {
   edges {
     node {
      id
      slug
      category {
        category
        id
      }
      list {
        url
      }
      subttl
      title
      photo {
        image {
          url
        }
      }
     }
   }
  }
  allMicrocmsTop {
    edges {
      node {
        main_photo {
          image {
            url
          }
        }
      }
    }
  }
  allMicrocmsInformation(filter: {top: {eq: true}}) {
    edges {
      node {
        id
        date
        text
        top
        photo {
          url
        }
      }
    }
  }
 }
`

export default IndexPage